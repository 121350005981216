<!-- 系统设置 权限管理 账号管理 新增或编辑或数据权限 -->
<template>
  <ykc-drawer :show.sync="showDrawer" destroy-on-close :title="drawerTitle">
    <template #footer>
      <div class="drawer-button-wrap">
        <template v-for="(btn, i) in drawerButtons">
          <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
            {{ btn.label }}
          </ykc-button>
        </template>
      </div>
    </template>
    <div class="drawer-main-body">
      <ykc-form ref="YkcForm" :model="ruleForm" :rules="rules">
        <ykc-form-item label="充电电量（度）" prop="sumDegree">
          <ykc-input
            v-model="ruleForm.sumDegree"
            maxlength="50"
            placeholder="请输入充电电量"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="充电时长（min）" prop="sumMinute">
          <ykc-input
            v-model="ruleForm.sumMinute"
            maxlength="50"
            placeholder="请输入充电时长"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="订单电费" prop="sumElectricCharge">
          <ykc-input
            v-model="ruleForm.sumElectricCharge"
            maxlength="50"
            placeholder="请输入订单电费"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="订单服务费" prop="sumServerCharge">
          <ykc-input
            v-model="ruleForm.sumServerCharge"
            maxlength="50"
            placeholder="请输入订单服务费"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="异常原因" prop="dealReason">
          <ykc-input
            type="textarea"
            maxlength="100"
            v-model="ruleForm.dealReason"
            placeholder="请输入异常原因"></ykc-input>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-drawer>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog';

  import regexpObj from '@/utils/regexp';
  import { orderPage } from '@/service/apis';

  export default {
    props: {
      tradeSeq: {
        type: String,
      },
      showDrawer: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        drawerTitle: '异常订单处理',
        ruleForm: {
          tradeSeq: '',
          sumMinute: null,
          sumElectricCharge: '',
          sumServerCharge: '',
          sumDegree: '',
          dealReason: '',
        },
        rules: {
          sumDegree: [
            { required: true, message: '请输入充电电量（度）', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.priceNumberType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.priceNumberType.errorTips.error(
                '充电电量（度）',
                99999999
              ),
            },
          ],
          sumMinute: [
            { required: true, message: '请输入充电时长（min）', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.integerNumberType.errorTips.error(
                '充电时长（min）',
                99999999
              ),
            },
          ],
          sumElectricCharge: [
            { required: true, message: '请输入订单电费', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.amountNumberType.regexp,
              message: regexpObj.regexp.input.amountNumberType.errorTips.error(
                '订单电费',
                99999999
              ),
              trigger: 'blur',
            },
          ],
          sumServerCharge: [
            { required: true, message: '请输入订单服务费', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.amountNumberType.regexp,
              message: regexpObj.regexp.input.amountNumberType.errorTips.error(
                '订单服务费',
                99999999
              ),
              trigger: 'blur',
            },
          ],
          dealReason: [
            { required: true, message: '请输入异常原因', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.inlineTextType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.inlineTextType.errorTips.error('异常原因'),
            },
          ],
        },
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => {
              return true;
            },
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.$emit('closeDrawer');
                  this.ruleForm = {
                    tradeSeq: '',
                    sumMinute: null,
                    sumElectricCharge: '',
                    sumServerCharge: '',
                    sumDegree: '',
                    dealReason: '',
                  };
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => {
              return true;
            },
            handler: () => {
              this.$refs.YkcForm.validate(valid => {
                if (valid) {
                  this.beforeEnsure();
                }
              });
            },
          },
        ],
      };
    },
    methods: {
      // 保存
      beforeEnsure() {
        this.ruleForm.tradeSeq = this.tradeSeq;
        this.ruleForm.sumMinute = Number(this.ruleForm.sumMinute);
        orderPage
          .balanceAbnormalOrder(this.ruleForm)
          .then(res => {
            console.log(res);
            this.$message({
              message: '保存成功',
              type: 'success',
            });
            this.$router.push({
              path: '/orderManagement/historicalOrder/List',
              query: {},
            });
            this.$emit('closeDrawer');
            this.ruleForm = {
              tradeSeq: '',
              sumMinute: null,
              sumElectricCharge: '',
              sumServerCharge: '',
              sumDegree: '',
              dealReason: '',
            };
          })
          .catch(() => {});
      },
    },
  };
</script>

<style lang="scss" scoped></style>
